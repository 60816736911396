<template>
  <div>
    <object
      data="https://www.epuris.ca/pdf/EPURIS_PatientBrochure_ENG.pdf#pagemode=thumbs&scrollbar=1&toolbar=1&statusbar=1&messages=1&navpanes=1"
      type="application/pdf"
      width="100%"
      height="1000"
    >
    </object>
  </div>
</template>

<script>

export default {
    components: {
    }
}
</script>
